import React, { useState, useEffect } from "react";
import MyProfile from "./MyProfile";
import Plans from "./Plans";

const AccountsAndSettings = () => {
    const tabs = ["myprofile", "plans"];
    const activeLineClasses = ["line_myprofile", "line_plans"];

    const [activeTab, setActiveTab] = useState("myprofile");

    useEffect(() => {
        setActiveTab("plans");
    }, []);

    const showTabPane = () => {
        if (activeTab === tabs[0]) {
            return <MyProfile />;
        } else if (activeTab === tabs[1]) {
            return <Plans />;
        }
    };

    const displayActiveLine = () => {
        if (activeTab === tabs[0]) {
            return activeLineClasses[0];
        } else if (activeTab === tabs[1]) {
            return activeLineClasses[1];
        }
    };

    return (
        <>
            <div className="db-CardlessUIPageWrapper Box-root">
                <div className="Box-root Padding-top--16 Padding-bottom--8 Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
                    <span className="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                        <span>Account Settings</span>
                    </span>
                </div>
                {/* Account Settings Heading */}

                <div>
                    {/* Tab items */}
                    <div className="tabs">
                        <div
                            onClick={() => setActiveTab(tabs[0])}
                            className={`tab-item ${
                                activeTab === tabs[0] ? "active" : ""
                            }`}
                        >
                            My Profile&nbsp;
                        </div>
                        <div
                            onClick={() => setActiveTab(tabs[1])}
                            className={`tab-item ${
                                activeTab === tabs[1] ? "active" : ""
                            }`}
                        >
                            Plans
                        </div>
                        <div className={`line ${displayActiveLine()}`} />
                    </div>
                    {/* Tab Content */}
                    <div className="tab-content" style={{ paddingBottom: 0 }}>
                        {showTabPane()}
                    </div>
                    {/* tab-content */}
                </div>
                {/* Wrapper Div */}
            </div>
            {/* db-CardlessUIPageWrapper */}
        </>
    );
};

export default AccountsAndSettings;
